import { Tabbar } from '@telegram-apps/telegram-ui';
import { IoIosAddCircleOutline, IoIosNotificationsOutline } from 'react-icons/io';
import { FaListUl } from 'react-icons/fa';
import { AiOutlineMessage } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './TabsMenu.module.scss';

type TabConfig = {
    title: string;
    pathname: string;
    icon: React.ReactNode;
};

const tabs: TabConfig[] = [
    {
        title: 'Добавить',
        pathname: '/add-product',
        icon: <IoIosAddCircleOutline />,
    },
    {
        title: 'Товары',
        pathname: '/products',
        icon: <FaListUl />,
    },
    {
        title: 'Уведомления',
        pathname: '/notifications',
        icon: <IoIosNotificationsOutline />,
    },
    {
        title: 'Помощь',
        pathname: '/help',
        icon: <AiOutlineMessage />,
    },
];

export function TabsMenu() {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    return (
        <Tabbar className={styles.tabs}>
            {tabs.map(({ title, pathname: path, icon }) => (
                <Tabbar.Item key={path} text={title} selected={pathname === path} onClick={() => navigate(path)} className={styles.tabItem}>
                    {icon}
                </Tabbar.Item>
            ))}
        </Tabbar>
    );
}
