import { TabsMenu } from '../TabsMenu';
import styles from './ContentWrapper.module.scss';

export function ContentWrapper({ children }: React.PropsWithChildren) {
    return (
        <div className={styles.container}>
            {children}
            <TabsMenu />
        </div>
    );
}
