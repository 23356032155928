import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { retrieveLaunchParams } from '@telegram-apps/sdk-react';
import axios from 'axios';
import type { AxiosRequestConfig, AxiosError } from 'axios';

export const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: '' },
    ): BaseQueryFn<
        {
            url: string;
            method?: AxiosRequestConfig['method'];
            data?: AxiosRequestConfig['data'];
            params?: AxiosRequestConfig['params'];
            headers?: AxiosRequestConfig['headers'];
        },
        unknown,
        unknown
    > =>
    async ({ url, method, data, params, headers }) => {
        const { initDataRaw } = retrieveLaunchParams();

        try {
            const result = await axios({
                url: baseUrl + url + (initDataRaw ? `?${initDataRaw}` : ''),
                method,
                data,
                params: params,
                headers,
                timeout: 0,
            });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };
