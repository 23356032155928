import './sentry';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import { SDKProvider } from '@telegram-apps/sdk-react';
import '@telegram-apps/telegram-ui/dist/styles.css';
import '@/styles/global.scss';
import { Provider } from 'react-redux';
import { store } from './store/index.ts';
import { ErrorBoundary } from './components/ErrorBoundary';

createRoot(document.getElementById('root')!).render(
    <StrictMode>
        <SDKProvider>
            <Provider store={store}>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </Provider>
        </SDKProvider>
    </StrictMode>,
);
