import { useGetUserQuery } from '@/store/api/user';
import { Text } from '@telegram-apps/telegram-ui';
import { PageSpinner } from '../PageSpinner';
import { FullPage } from '../FullPage';

export function Auth({ children }: React.PropsWithChildren) {
    const { isLoading, isSuccess, isError } = useGetUserQuery();

    if (isLoading) {
        return <PageSpinner />;
    }

    if (isError) {
        return (
            <FullPage>
                <Text>Произошла ошибка при загрузке данных профиля</Text>
            </FullPage>
        );
    }

    if (isSuccess) {
        return <>{children}</>;
    }
}
