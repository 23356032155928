import { Spinner } from '@telegram-apps/telegram-ui';
import { FullPage } from '../FullPage';

export function PageSpinner() {
    return (
        <FullPage>
            <Spinner size="l" />
        </FullPage>
    );
}
