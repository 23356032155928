import {
    initNavigator,
    useMiniApp,
    useLaunchParams,
    useThemeParams,
    useViewport,
    bindMiniAppCSSVars,
    bindThemeParamsCSSVars,
    bindViewportCSSVars,
    useSwipeBehavior,
} from '@telegram-apps/sdk-react';
import { useIntegration } from '@telegram-apps/react-router-integration';
import { lazy, Suspense, useEffect, useMemo } from 'react';
import { Navigate, Route, Router, Routes } from 'react-router-dom';
import { AppRoot } from '@telegram-apps/telegram-ui';
import { IconContext } from 'react-icons/lib';
import { ContentWrapper } from '@/components/ContentWrapper';
import { Auth } from './components/Auth';
import { SnackbarProvider } from 'notistack';
import { PageSpinner } from './components/PageSpinner';

const AddProduct = lazy(() => import('./pages/AddProduct').then((module) => ({ default: module.AddProduct })));
const AllProducts = lazy(() => import('./pages/AllProducts').then((module) => ({ default: module.AllProducts })));
const Notifications = lazy(() => import('./pages/Notifications').then((module) => ({ default: module.Notifications })));
const Help = lazy(() => import('./pages/Help').then((module) => ({ default: module.Help })));

function App() {
    const lp = useLaunchParams();
    const miniApp = useMiniApp();
    const themeParams = useThemeParams();
    const viewport = useViewport();
    const swipeBehavior = useSwipeBehavior();
    const navigator = useMemo(() => initNavigator('app-navigation-state'), []);
    const [location, reactNavigator] = useIntegration(navigator);

    useEffect(() => {
        return bindMiniAppCSSVars(miniApp, themeParams);
    }, [miniApp, themeParams]);

    useEffect(() => {
        return bindThemeParamsCSSVars(themeParams);
    }, [themeParams]);

    useEffect(() => {
        return viewport && bindViewportCSSVars(viewport);
    }, [viewport]);

    useEffect(() => {
        navigator.attach();

        return () => {
            navigator.detach();
        };
    }, [navigator]);

    useEffect(() => {
        navigator.replace(lp.initData?.startParam?.split('_')[0] || '/');
    }, [lp.initData, navigator]);

    useEffect(() => {
        document.documentElement.classList.toggle('dark', miniApp.isDark);
    }, [miniApp.isDark]);

    useEffect(() => {
        if (swipeBehavior.supports('disableVerticalSwipe')) {
            try {
                swipeBehavior.disableVerticalSwipe();
            } catch (err) {
                console.error(err);
            }
        }
    }, [swipeBehavior]);

    useEffect(() => {
        if (viewport?.isStable && !viewport.isExpanded) {
            viewport?.expand();
        }
    }, [viewport, viewport?.isStable, viewport?.isExpanded]);

    return (
        <AppRoot appearance={miniApp.isDark ? 'dark' : 'light'} platform="base">
            <IconContext.Provider value={{ size: '1.5em' }}>
                <Router location={location} navigator={reactNavigator}>
                    <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={1500} maxSnack={1}>
                        <Auth>
                            <ContentWrapper>
                                <Routes>
                                    <Route
                                        path="/add-product"
                                        element={
                                            <Suspense fallback={<PageSpinner />}>
                                                <AddProduct />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="/products"
                                        element={
                                            <Suspense fallback={<PageSpinner />}>
                                                <AllProducts />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="/notifications"
                                        element={
                                            <Suspense fallback={<PageSpinner />}>
                                                <Notifications />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path="/help"
                                        element={
                                            <Suspense fallback={<PageSpinner />}>
                                                <Help />
                                            </Suspense>
                                        }
                                    />
                                    <Route path="*" element={<Navigate to="/add-product" />} />
                                </Routes>
                            </ContentWrapper>
                        </Auth>
                    </SnackbarProvider>
                </Router>
            </IconContext.Provider>
        </AppRoot>
    );
}

export default App;
