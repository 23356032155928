import { extractError } from '@/utils/extractError';
import { Component, ErrorInfo } from 'react';
import styles from './ErrorBoundary.module.scss';

type ErrorBoundaryState = {
    hasError: boolean;
    text: string;
    stack: string;
};

export class ErrorBoundary extends Component<React.PropsWithChildren, ErrorBoundaryState> {
    state = {
        hasError: false,
        text: '',
        stack: '',
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('ErrorBoundary caught an error: ', error, errorInfo);
    }

    static getDerivedStateFromError(error: unknown) {
        const errorText = extractError(error);
        let stack: string = '';

        if (error instanceof Error) {
            stack = error.stack || '';
        }

        return { hasError: true, stack, text: errorText };
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className={styles.error}>
                    <h1>Что-то пошло не так</h1>
                    <p>Текст ошибки:</p>
                    <p>{this.state.text}</p>
                    <button></button>
                </div>
            );
        }

        return this.props.children;
    }
}
